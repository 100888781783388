<template>
  <section>
    <a-divider class="dividerTitle text-outline">
      {{ $t("pages.reset-password.title") }}
    </a-divider>
    <!-- <a-form-item>
      <div class="subtitle">
        {{ $t('pages.reset-password.subtitle') }}
      </div>
    </a-form-item> -->
    <a-form
      id="components-form-demo-normal-reset-password"
      :form="form"
      class="reset-password-form"
      @submit="handleSubmit"
    >
      <div class="subtitle text-outline">
        {{ $t("pages.reset-password.subtitle") }}
      </div>
      <a-form-item
        v-bind="formItemLayout"
        :label="$t('ui.Password')"
        class="text-outline"
      >
        <a-input
          v-decorator="[
            'password',
            {
              rules: [
                {
                  required: true,
                  message: $t('ui.ProvidePassword'),
                },
                {
                  validator: validateToNextPassword,
                },
              ],
            },
          ]"
          type="password"
        />
      </a-form-item>
      <a-form-item
        v-bind="formItemLayout"
        :label="$t('ui.Password')"
        class="text-outline"
      >
        <a-input
          v-decorator="[
            'confirm',
            {
              rules: [
                {
                  required: true,
                  message: $t('ui.ConfirmPassword'),
                },
                {
                  validator: compareToFirstPassword,
                },
              ],
            },
          ]"
          type="password"
          @blur="handleConfirmBlur"
        />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" :disabled="loading">
          {{ this.$t("buttons.submit") }}
        </a-button>
      </a-form-item>
    </a-form>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  middleware: "notAuthenticated",
  data() {
    return {
      loading: false,
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      },
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        const params = this.$route.params;
        if (!err && params.code) {
          // console.log('Received values of form: ', values)
          this.loading = true;
          this["authCustom/resetPasswordAction"]({
            code: params.code,
            password: values.password,
          });
          this.loading = false;

          this.$notification["success"]({
            message: this.$t("ui.SuccessOccurred"),
            description: false,
          });
          this.$router.push("/auth/login");
          // }
        }
      });
    },
    ...mapActions(["authCustom/resetPasswordAction"]),
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback(this.$t("ui.passwordsInconsistent"));
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    },
    ...mapActions(["authCustom/resetPasswordAction"]),
  },
};
</script>

<style scoped>
.reset-password-form {
  margin: 20px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.subtitle {
  margin-bottom: 15px;
}

@media only screen and (max-width: 700px) {
  .dividerTitle {
    display: none;
  }
}
</style>
