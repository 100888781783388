var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('a-divider',{staticClass:"dividerTitle text-outline"},[_vm._v(" "+_vm._s(_vm.$t("pages.reset-password.title"))+" ")]),_c('a-form',{staticClass:"reset-password-form",attrs:{"id":"components-form-demo-normal-reset-password","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"subtitle text-outline"},[_vm._v(" "+_vm._s(_vm.$t("pages.reset-password.subtitle"))+" ")]),_c('a-form-item',_vm._b({staticClass:"text-outline",attrs:{"label":_vm.$t('ui.Password')}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('ui.ProvidePassword'),
              },
              {
                validator: _vm.validateToNextPassword,
              },
            ],
          },
        ]),expression:"[\n          'password',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('ui.ProvidePassword'),\n              },\n              {\n                validator: validateToNextPassword,\n              },\n            ],\n          },\n        ]"}],attrs:{"type":"password"}})],1),_c('a-form-item',_vm._b({staticClass:"text-outline",attrs:{"label":_vm.$t('ui.Password')}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'confirm',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('ui.ConfirmPassword'),
              },
              {
                validator: _vm.compareToFirstPassword,
              },
            ],
          },
        ]),expression:"[\n          'confirm',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('ui.ConfirmPassword'),\n              },\n              {\n                validator: compareToFirstPassword,\n              },\n            ],\n          },\n        ]"}],attrs:{"type":"password"},on:{"blur":_vm.handleConfirmBlur}})],1),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","disabled":_vm.loading}},[_vm._v(" "+_vm._s(this.$t("buttons.submit"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }